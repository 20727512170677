import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from '../core.state';
import { environment } from '../../../environments/environment';
import { Settings, SettingsResponse} from '../../models/settings.model';
import { Injectable } from '@angular/core';
import { SavedRepliesResponse, SavedReply, SavedReplyResponse } from '../../models';
import * as fromModels from "../../models/index";
import { ChatFlowsResponse, ChatFlow } from '../../models/chatflow.model';


const API_URL = environment.apiUrl;

@Injectable()
export class ChatFlowsService {

    constructor(
      private http: HttpClient, 
      private store: Store<AppState> ) {
    }

    public getChatFlows(organization_id: number) : Observable<ChatFlowsResponse>  {
        return this.http.get<ChatFlowsResponse>(
            `${API_URL}/api/chatflows?organization_id=${organization_id}`
          );
    }

    public addChatflow(c: ChatFlow[]) : Observable<ChatFlowsResponse>  {
      if (c[0].group_id){
        // it's an edit
        return this.http.put<ChatFlowsResponse>(`${API_URL}/api/chatflows/${c[0].group_id}`, c);
      } else {
        // it's a create
        return this.http.post<ChatFlowsResponse>(`${API_URL}/api/chatflows`, c);
      }
    }

    public deleteChatflow(id: number) : Observable<fromModels.Response>  {
      return this.http.delete<fromModels.Response>(`${API_URL}/api/chatflows/${id}`);
    }

}