import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromSettings from './settings.reducers';
import { SettingsState } from './settings.reducers';


export const selectSettingsState = createFeatureSelector<SettingsState>('settings');

export const selectOrganizationState = createSelector(selectSettingsState, fromSettings.selectOrganizationState);
export const selectMemberState = createSelector(selectSettingsState, fromSettings.selectMemberState);
export const selectTagsState = createSelector(selectSettingsState, fromSettings.selectTagsState);
export const selectTicketTagsState = createSelector(selectSettingsState, fromSettings.selectTicketTagsState);


export const selectAllOrganizations = createSelector(selectOrganizationState, fromSettings.selectAllOrganizations);

// @ps All members returns also deleted members, because we need them
// for displaying previous conversations where they were engaged in
export const selectAllMembers = createSelector(selectMemberState, fromSettings.selectAllMembers);

export const selectMemberActionLoading = createSelector(
    selectSettingsState,
    settings => settings.saveMemberLoading
);

export const selectMemberById = ({member_id}) => createSelector(
    selectSettingsState,
    settings => settings.members.entities[member_id]
);

export const selectNotificationSaveLoading = createSelector(
    selectSettingsState,
    settings => settings.saveNotificationsLoading
);

export const selectNotificationTranscript = createSelector(
    selectSettingsState, s =>  s.notification_transcript
);

export const selectNotificationMissed = createSelector(
    selectSettingsState, s =>  s.notification_missed
);

export const selectNotificationNewchat = createSelector(
    selectSettingsState, s =>  s.notification_newchat
);

export const selectNotificationOffline = createSelector(
    selectSettingsState, s =>  s.notification_offline
);

export const selectNotificationNewTickets = createSelector(
    selectSettingsState, s =>  s.notification_newticket
);

export const selectNotificationTicketReplies = createSelector(
    selectSettingsState, s =>  s.notification_ticketreplies
);

export const selectIsSettingsLoaded = createSelector(
    selectSettingsState,
    settings => settings.isSettingsLoaded
);

export const selectSubscription = createSelector(
    selectSettingsState,
    settings => settings.subscription
);

export const selectMembersCount = createSelector(
    selectAllMembers,
    users => users.filter(q => !q.is_deleted).length
);

export const selectAllContactTags = createSelector(
    selectTagsState, fromSettings.selectAllTags
);

export const selectTagSaveLoading = createSelector(
    selectTagsState,
    tags => tags.saveLoading
);

export const selectWidgetLanguages = createSelector(
    selectSettingsState,
    settings => settings.widget_languages
);

export const selectAllTriggers = createSelector(
    selectSettingsState,
    settings => settings.triggers
);

export const selectAllNews = createSelector(
    selectSettingsState,
    settings => settings.news
);

/**
 * Tickets Related Settings
 */

export const selectAllTicketsTags = createSelector(
    selectTicketTagsState, fromSettings.selectAllTicketTags
);

export const selectTicketsTagSaveLoading = createSelector(
    selectTicketTagsState,
    tags => tags.saveLoading
);

