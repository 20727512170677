import { Injectable } from '@angular/core';
import { OnInitEffects, Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';
import { combineLatest, of, empty } from 'rxjs';
import { AppState } from '../core.state';
import { map, mergeMap, skipWhile, withLatestFrom, tap, catchError, switchMap } from 'rxjs/operators';

import * as ChatFlowsActions from './chatflow.actions'
import { ChatFlowsService } from '../services/chatflows.service';
import { currentOrganization } from '../auth';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

import * as ToastrActions  from './toastr.actions';
import { cloneDeep } from 'lodash-es';


@Injectable()
export class ChatFlowsEffects implements OnInitEffects  {

    loadChatFlows = createEffect(() => this.actions$.pipe(
        ofType(ChatFlowsActions.ChatFlowsRequested),
        mergeMap(action => {
            return combineLatest(
              of(action),
              this.store.pipe(select(currentOrganization))
            )
             }
          ),
        skipWhile(([action, org]) => (org == null) ),
        mergeMap( ([action, org]) => 
                this.chatflowService.getChatFlows( org.id )
            ),
        map( (s) => {
            return ChatFlowsActions.ChatFlowsLoaded({ChatFlows: s.model});
        })
    ));

    saveChatflow = createEffect(() => this.actions$.pipe(
        ofType(ChatFlowsActions.ChatFlowSaveRequested),
        withLatestFrom(this.store.pipe(select(currentOrganization))),
        mergeMap( ([action, org]) => {
            const cf = cloneDeep(action.ChatFlow);
            cf[0].group_organization_id = org.id;
            return this.chatflowService.addChatflow( cf).pipe(
                switchMap(res => [
                    ChatFlowsActions.ChatFlowSaveSucess({ ChatFlow: res.model }),
                    ToastrActions.showToastrSuccess( {message:cf[0].group_name + ' has been successfully saved.', title: ''})
                ]),
                catchError((error: HttpErrorResponse) => of(
                    ChatFlowsActions.ChatFlowSaveFailed({message: error.error}),
                    ToastrActions.showToastrError({message: error.error , title: 'Something went wrong'})
                ))
            );
        })
    ));

    deleteChatflow = createEffect(() => this.actions$.pipe(
        ofType(ChatFlowsActions.ChatFlowDeleteRequested),
        mergeMap( action => {
            return this.chatflowService.deleteChatflow(action.id).pipe(
                switchMap(res => [
                    ChatFlowsActions.ChatFlowDeleteSucess({ id: action.id }),
                    ToastrActions.showToastrSuccess( {message: 'Chatflow has been successfully deleted', title: ''})
                ]),
                catchError((error: HttpErrorResponse) => of(
                    ChatFlowsActions.ChatFlowDeleteFailed({message: error.error}),
                    ToastrActions.showToastrError({message: error.error , title: 'Something went wrong'})
                ))
            );
        })
    ));

    ngrxOnInitEffects(): Action {
        return { type: ChatFlowsActions.ChatFlowsRequested.type };
    }
    
    constructor(
        private chatflowService: ChatFlowsService,
        private actions$: Actions,
        private store: Store<AppState>,
        private toastr: ToastrService
    ) { }

}