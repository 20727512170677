import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules, NoPreloading } from '@angular/router';
import { ConnectGuard } from './core/connect.guard';
import { ErrorPageComponent } from './core/error-page/error-page.component';

const routes: Routes = [
	{path: '', loadChildren: () => import('./layout/backend.module').then(m => m.BackendModule)},
	{path: 'auth', loadChildren: () => import('./identity-ui/auth-identity.module').then(m => m.AuthIdentityModule)},

	/**
	 * @ps this should work for all error pages, but for some reason its not
	 * {path: 'error', component: ErrorPageComponent},
	 */
	{path: 'error/404', component: ErrorPageComponent},
	{path: 'error/403', component: ErrorPageComponent},
	{path: 'error/identity', component: ErrorPageComponent},
	{path: '**', component: ErrorPageComponent, canActivate: [ConnectGuard],},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
    /* @ps If we chose to use PreloadAllModules, the hub and
    conversations service will be loaded on login/register/forgot-password,
    A good alternative would be the QuickLinkStrategy https://github.com/mgechev/ngx-quicklink */
    preloadingStrategy: NoPreloading
})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}

