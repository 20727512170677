import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import * as ToastrActions from '../../core/store/toastr.actions';

import { of } from 'rxjs';
import { currentOrganization } from '../../core/auth';
import { AppState } from '../../core/core.state';
import { KbService } from '../services/kb.service';
import * as KbActions from './kb.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { KbArticle } from '../models/kbarticle.model';
import { Router } from '@angular/router';


@Injectable()
export class KbEffects {

    kbRequest = createEffect(() => this.actions$.pipe(
        ofType(KbActions.KbRequest),
        mergeMap(action => combineLatest([
                of(action),
                this.store.pipe( select(currentOrganization), filter(o => o != null), take(1) )
                ])
        ),
        mergeMap( ([action, org])  => this.KbService.getKb(org.id)),
        switchMap( res => [
            KbActions.KbRequestSuccess({ Kb: res.model})
        ])
    
    ));

    kbArticleRequest = createEffect(() => this.actions$.pipe(
        ofType(KbActions.KbArticleRequest),
        mergeMap( action  => this.KbService.getKbArticle(action.article_id).pipe(
            switchMap( res => {
                const updated_article: Update<KbArticle> = {
                    id: action.article_id,
                    changes: {
                        article: res.model.article
                    }
                };
                return [
                    KbActions.KbArticleRequestSuccess({ article: updated_article})
                ];
            }),
            catchError((er: HttpErrorResponse) => of(
                ToastrActions.showToastrError({message: 'Problems while retrieving article. The error has been reported.' , title: 'Something went wrong'})
            ))
        ))
    
    ));

    kbArticleSaveRequest = createEffect(() => this.actions$.pipe(
        ofType(KbActions.KbArticleSaveRequest),
        mergeMap(action => this.KbService.saveKbArticle(action.article).pipe(
            switchMap(res => [
                KbActions.KbArticleSaveSuccess({article: res.model}),
                ToastrActions.showToastrSuccess({message: 'Article was successfully saved.' , title: ''})
            ]),
            catchError((er: HttpErrorResponse) => of(
                KbActions.KbArticleSaveFailed({message: 'Problems while saving the article' }),
                ToastrActions.showToastrError({message: 'Problems while saving article. The error has been reported.' , title: 'Something went wrong'})
            ))
        ))
    ));

    kbArticleDeleteRequest = createEffect(() => this.actions$.pipe(
        ofType(KbActions.KbArticleDeleteRequest),
        mergeMap(action => this.KbService.deleteArticle(action.article_id).pipe(
            switchMap(res => [
                KbActions.KbArticleDeleteSuccess({article_id: action.article_id}),
                ToastrActions.showToastrSuccess({message: 'Article was successfully deleted.' , title: ''})
            ]),
            catchError((er: HttpErrorResponse) => of(
                KbActions.KbArticleDeleteFailed({message: 'Problems while deleting the article' }),
                ToastrActions.showToastrError({message: 'Problems while deleting the article. The error has been reported.' , title: 'Something went wrong'})
            ))
        ))
    ));

    kbCategorySaveRequest = createEffect(() => this.actions$.pipe(
        ofType(KbActions.KbCategorySaveRequest),
        mergeMap(action => this.KbService.saveKbCategory(action.category).pipe(
            switchMap(res => [
                KbActions.KbCategorySaveSuccess({category: res.model}),
                ToastrActions.showToastrSuccess({message: 'Article was successfully saved.' , title: ''})
            ]),
            catchError((er: HttpErrorResponse) => of(
                KbActions.KbCategorySaveFailed({message: 'Problems while saving the category' }),
                ToastrActions.showToastrError({message: 'Problems while saving category. The error has been reported.' , title: 'Something went wrong'})
            ))
        ))
    ));

    kbCategoryDeleteRequest = createEffect(() => this.actions$.pipe(
        ofType(KbActions.KbCategoryDeleteRequest),
        mergeMap(action => this.KbService.deleteCategory(action.category_id).pipe(
            switchMap(res => [
                KbActions.KbCategoryDeleteSuccess({category_id: action.category_id}),
                ToastrActions.showToastrSuccess({message: 'Category was successfully deleted.' , title: ''})
            ]),
            catchError((er: HttpErrorResponse) => of(
                KbActions.KbCategoryDeleteFailed({message: 'Problems while deleting the category' }),
                ToastrActions.showToastrError({message: 'Problems while deleting the category. The error has been reported.' , title: 'Something went wrong'})
            ))
        ))
    ));

    ngrxOnInitEffects(): Action {
        return { type: KbActions.KbRequest.type };
    }

    constructor(
        private store: Store<AppState>,
        private actions$: Actions,
        private KbService: KbService,
        private router: Router
    ) { }

}