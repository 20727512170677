import { createAction, props } from '@ngrx/store';
import { SavedReply } from '../../models/saved.reply.model';

export const SavedRepliesRequested = createAction(
    '[Core] Saved Replies Requested',
    props<{organization_id: number;}>(),
)

export const SavedRepliesLoaded = createAction(
    '[Core] Saved Replies Loaded',
    props<{SaveReplies: SavedReply[];}>(),
)

export const SavedReplySaveFailed = createAction(
    '[Core] Saved Replies Failed Saving',
    props<{message: string;}>()
)

export const SavedReplySaveRequested = createAction(
    '[Core] Saved Replies Save Requested',
    props<{SavedReply: SavedReply;}>(),
)

export const SavedReplyDeleteRequested = createAction(
    '[Core] Saved Replies Delete Requested',
    props<{id: number;}>(),
)

export const SavedReplyDeleteSucess = createAction(
    '[Core] Saved Replies Delete Success',
    props<{id: number}>(),
)

export const SavedReplyDeleteFailed = createAction(
    '[Core] Saved Replies Delete Failed',
    props<{message: string;}>()
)

export const SavedReplySaveSucess = createAction(
    '[Core] Saved Replies Save Success',
    props<{SavedReply: SavedReply;}>(),
)
