import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from '../core.state';
import { environment } from '../../../environments/environment';
import { Settings, SettingsResponse} from '../../models/settings.model';
import { Injectable } from '@angular/core';
import { SavedRepliesResponse, SavedReply, SavedReplyResponse } from '../../models';
import * as fromModels from "../../models/index";


const API_URL = environment.apiUrl;

@Injectable()
export class SavedRepliesService {

    constructor(
      private http: HttpClient, 
      private store: Store<AppState> ) {
    }

    public getSavedReplies(organization_id: number) : Observable<SavedRepliesResponse>  {
        return this.http.get<SavedRepliesResponse>(
            `${API_URL}/api/savedreplies?organization_id=${organization_id}`
          );
    }

    public addSavedReply(sr: SavedReply) : Observable<SavedReplyResponse>  {
      if (sr.id){
        // it's an edit
        return this.http.put<SavedReplyResponse>(`${API_URL}/api/savedreplies/${sr.id}`, sr);
      } else {
        // it's a create
        return this.http.post<SavedReplyResponse>(`${API_URL}/api/savedreplies`, sr);
      }
    }

    public deleteSavedReply(id: number) : Observable<fromModels.Response>  {
      return this.http.delete<fromModels.Response>(`${API_URL}/api/savedreplies/${id}`);
    }

}