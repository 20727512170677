import { Injectable } from '@angular/core';
import { OnInitEffects, Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';
import { combineLatest, of, empty } from 'rxjs';
import { AppState } from '../core.state';
import { map, mergeMap, skipWhile, withLatestFrom, tap, catchError, switchMap, filter, take } from 'rxjs/operators';

import * as SavedRepliesActions from './saved.replies.actions'
import { SavedRepliesService } from '../services/saved.replies.service';
import { currentUser, currentOrganization } from '../auth';
import { ToastrService } from 'ngx-toastr';
import * as ToastrActions  from './toastr.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { cloneDeep } from 'lodash-es';

@Injectable()
export class SavedRepliesEffects implements OnInitEffects  {

    loadSavedReplies = createEffect(() => this.actions$.pipe(
        ofType(SavedRepliesActions.SavedRepliesRequested),
        mergeMap(action => this.store.pipe(
            select(currentUser),
            filter(u => u != null),
            take(1)
        )),
        switchMap( user => 
            this.savedRepliesService.getSavedReplies( user.organizations[0].id )
        ),
        map( (s) => SavedRepliesActions.SavedRepliesLoaded({SaveReplies: s.model}) )
    ));

    saveSavedReply = createEffect(() => this.actions$.pipe(
        ofType(SavedRepliesActions.SavedReplySaveRequested),
        withLatestFrom(this.store.pipe(select(currentOrganization))),
        mergeMap( ([action, org]) => {
            let sr = cloneDeep(action.SavedReply);
            sr.organization_id = org.id;
            return this.savedRepliesService.addSavedReply(sr).pipe(
                switchMap(res => [
                    SavedRepliesActions.SavedReplySaveSucess({SavedReply: res.model}),
                    ToastrActions.showToastrSuccess({message:'Saved Reply has been successfully saved.', title: ''})
                ]),
                catchError((error: HttpErrorResponse) => of(
                    SavedRepliesActions.SavedReplySaveFailed({message: error.error}),
                    ToastrActions.showToastrError({message: error.error , title: 'Something went wrong'})
                ))
            );
        })
    ));

    deleteSavedReply = createEffect(() => this.actions$.pipe(
        ofType(SavedRepliesActions.SavedReplyDeleteRequested),
        mergeMap( action => 
            this.savedRepliesService.deleteSavedReply(action.id).pipe(
                switchMap(res => [
                    SavedRepliesActions.SavedReplyDeleteSucess({ id: action.id }),
                    ToastrActions.showToastrSuccess({message:'Saved Reply has been successfully deleted', title: ''})
                ]),
                catchError((error: HttpErrorResponse) => of(
                    SavedRepliesActions.SavedReplyDeleteFailed({message: error.error}),
                    ToastrActions.showToastrError({message: error.error , title: 'Something went wrong'})
                ))
            )
        )
    ));

    ngrxOnInitEffects(): Action {
        return { type: SavedRepliesActions.SavedRepliesRequested.type };
    }
    
    constructor(
        private savedRepliesService: SavedRepliesService,
        private actions$: Actions,
        private store: Store<AppState>,
        private toastr: ToastrService
    ) { }

}