
import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LogService } from '../../core/services/log.service';
import { LayoutConfigService } from '../../core/_base/layout';

@Component({
	selector: 'kt-error-page',
	templateUrl: './error-page.component.html',
	styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit, OnDestroy {

	unsubscribe = new Subject();
	title: string;
	subtitle: string;
	desc: string;
	
	constructor(
		private activatedRoute: ActivatedRoute, 
		private layoutConfigService: LayoutConfigService,
		private log: LogService,
		private cdr: ChangeDetectorRef) {

		// set temporary values to the layout config on this page
		this.layoutConfigService.setConfig({self: {layout: 'blank'}});
		console.debug(this.activatedRoute.snapshot.toString());
		document.body.classList.add('kt-page--loaded');
	}


	ngOnInit() {

		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe( 
			params => {
				this.title = params.title ?? 'Hmm.';
				this.subtitle = params.subtitle ?? 'Something went wrong.';
				this.desc = params.desc ?? "We had some technical problems during your last operation. The error has been reported, please feel free to reach our support if you can't accomplish what you're trying to do.";

				let path = this.activatedRoute.toString();
				if ( path?.includes('identity') && params['errorId'] ){
					fetch(`/api/session/identity-error-details?errorId=${params.errorId}`)
						.then(res => {
								if (res.ok){
									res.json().then(err => {
										this.desc = `${err.error}. ${err.desc}. Client Id is ${err.client_id} `;
										this.cdr.markForCheck();
									});
								} else {
									this.log.critical(`Cannot find identity error with the id ${params.errorId}`, new Error("Identity Error Not Found"));
								}
							}
						);
				}
			});
	}

	ngOnDestroy(): void {
		// reset config from any temporary values
		this.layoutConfigService.reloadConfigs();

        this.unsubscribe.next();
        this.unsubscribe.complete();
	}
}
