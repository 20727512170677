import { props, createAction } from '@ngrx/store'
import { Activity, Visit, Message } from '../../models'

export const VisitRequested = createAction(
    '[Activities] Visit Requested',
    props<{id: number}>(),
)

export const VisitLoaded = createAction(
    '[Activities] Visit Loaded',
    props<{Visit: Visit}>(),
)

export const NewSession = createAction(
    '[Activities] New Session Created',
    props<{id: string, created_at: string, visitor_id: string}>(),
);

export const MessageActivityReceived = createAction(
    '[Activities] Message Requested',
    props<{message_id: string}>(),
);

export const MessageReadByVisitorReceived = createAction(
    '[Activities] Message Read By Visitor',
    props<{message_id: string}>(),
);

export const AgentActivityReceived = createAction(
    '[Activities] Agent Message Recieved',
    props<{message_id: string}>(),
);

export const MessageActivityLoaded = createAction(
    '[Activities] Message Loaded',
    props<{Message: Message}>(),
);

export const SendMessage = createAction(
    '[Activities] Send and dispatch message',
    props<{Message: Message}>(),
);

export const SendMessageFailed = createAction(
    '[Activities] Sending message returned a server error',
    props<{error: string, message_id: string}>(),
);

export const ActivitiesOfVisitorRequested = createAction(
    '[Activities] Activities Requested For Visitor',
    props<{visitor_id: string;}>(),
);

export const ActivitiesOfVisitorLoaded = createAction(
    '[Activities] Activities Loaded For Visitor',
    props<{Activities: Activity[];}>(),
);

export const EmailSessionRequest = createAction(
    '[Session] Email Session Request',
    props<{session_id: string, visitor_id: string}>(),
);

export const CloseChatSessionRequest = createAction(
    '[Session] Close Chat Session Request',
    props<{visitor_id: string}>()
);

export const DeleteSessionsRequest = createAction(
    '[Session] Delete All Visitors Sessions Request',
    props<{visitor_id: string}>()
);

