import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Property } from 'src/app/models';
import { SettingsService } from '../../settings/services/settings.service';
import { currentOrganization } from '../auth';
import * as AuthActions from '../auth/auth.actions';
import { AppState } from '../core.state';
import * as PropertyActions from './properties.actions';
import { selectPropertyById } from './properties.selectors';
import * as ToastrActions from './toastr.actions';
import * as KbActions from '../../kb/store/kb.actions';


@Injectable()
export class PropertiesEffects {

    saveProperty = createEffect(() => this.actions$.pipe(
        ofType(PropertyActions.PropertySave),
        withLatestFrom(this.store.pipe(select(currentOrganization))),
        mergeMap( ([action, org]) => 
            this.settingsService.saveProperty(action.property, org.id).pipe(
                switchMap(res => [
                    PropertyActions.PropertySaveSuccess({property: res.model}),
                    ToastrActions.showToastrSuccess({message: 'Property was successfully saved.', title: ''}),
                    // Refresh the user properties after we added a new one
                    AuthActions.UserRequested(),
                    // Refresh the isKbWidget flag in case the save is initiated from the quick wizard
                    KbActions.KbRequest()
                ]),
                catchError((error: HttpErrorResponse) => of( 
                    PropertyActions.PropertySaveFailed({message: error.error}),
                    ToastrActions.showToastrError({message: error.error , title: 'Something went wrong'})
                ))
            )
        )
    ));

    savePropertyFailed = createEffect(() => this.actions$.pipe(
        ofType(PropertyActions.PropertySaveFailed),
        mergeMap(action => of(ToastrActions.showToastrError({
            message: 'Error occured while saving property.' , 
            title: 'Something went wrong'
        })))
    ));

    deleteProperty = createEffect(() => this.actions$.pipe(
        ofType(PropertyActions.PropertyDeleteRequested),
        mergeMap( action => 
            this.settingsService.deleteProperty(action.id).pipe(
                switchMap(res => [
                    PropertyActions.PropertyDeleteSucess({ id: action.id }),
                    ToastrActions.showToastrSuccess({message: 'Property was successfully deleted.', title: ''})
                ]),
                catchError((error: HttpErrorResponse) => of(
                    PropertyActions.PropertyDeleteFailed({message: error.error}),
                    ToastrActions.showToastrError({message: error.error , title: 'Something went wrong'})
                ))
            )
        )
    ));

    deletePropertyFailed = createEffect(() => this.actions$.pipe(
        ofType(PropertyActions.PropertyDeleteFailed),
        mergeMap(action => of(ToastrActions.showToastrError({
            message: 'Error occured while deleting property.' , 
            title: 'Something went wrong'
        })))
    ));

    saveFacebookConnect = createEffect(() => this.actions$.pipe(
        ofType(PropertyActions.FacebookConnectRequest),
        mergeMap(action => 
            this.settingsService.connectFacebookPage(action.token, action.user_id, action.property_id).pipe(
                switchMap(res => {
                    const property_update: Update<Property> = {
                        id: action.property_id,
                        changes: {
                            facebook_pages: res.model
                        }
                    };
                    
                    return [
                        PropertyActions.FacebookConnectSuccess({property: property_update }),
                        ToastrActions.showToastrSuccess({message: 'Facebook page was connected', title: ''})
                    ];
                }),
                catchError((error: HttpErrorResponse) => of(
                    PropertyActions.FacebookConnectFailed({message: error.status == 400 ?
                        "Facebook Page is already linked with another property in or outside your organization.": "Error Connecting Facebook Page"}),
                    ToastrActions.showToastrError({message: error.status == 400 ?
                        "Facebook Page is already linked with another property in or outside your organization.": "Error Connecting Facebook Page" , 
                        title: 'Something went wrong'})
                ))
            )
        )
    ));

    unlinkFacebookPage = createEffect(() => this.actions$.pipe(
        ofType(PropertyActions.FacebookUnlinkRequest),
        mergeMap(action => this.settingsService.unlinkFacebookPage(action.page_id).pipe(
            switchMap(res => {
                const property_update: Update<Property> = {
                    id: action.property_id,
                    changes: {
                        facebook_pages: res.model
                    }
                };
                return [
                    PropertyActions.FacebookUnlinkSuccess({property: property_update }),
                    ToastrActions.showToastrSuccess({message: 'Facebook page was unlinked successfully', title: ''})
                ];
            }),
            catchError((error: HttpErrorResponse) => of(
                PropertyActions.FacebookUnlinkFailed({message: "We encountered an issue unlinking the page. Please try again." }),
                ToastrActions.showToastrError({message: "We encountered an issue unlinking the page. Please try again." , title: 'Something went wrong'})
                ))
        ))
    ));

    constructor(
        private settingsService: SettingsService,
        private actions$: Actions,
        private store: Store<AppState>
    ) { }

}