import { OAuthModuleConfig } from 'angular-oauth2-oidc';

export const identityModuleConfig: OAuthModuleConfig = {
  resourceServer: {
    allowedUrls: [
      'https://localhost:5001/api', 
      'https://app.getcharla.com/api',
      'https://app-dev.getcharla.com/api'
    ],
    sendAccessToken: true,
  }
};