import { AppState } from '../../core/core.state';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Property, Organization, User, Tag, WidgetLanguage, News } from '../../models';
import { createReducer, on, Action } from '@ngrx/store';
import * as SettingsActions from './settings.actions';
import { Subscription } from '../../billing/models/subscription.model';
import { Trigger } from 'src/app/models/trigger.model';


/* Define Organization State */
interface OrganizationsState extends EntityState<Organization> {
    total: number;
}
const adapterOrganization = createEntityAdapter<Organization>();  
const organizationInitialState: OrganizationsState = adapterOrganization.getInitialState({ total: 0 });

/* Define Members State */
interface MembersState extends EntityState<User> {
    total: number;
}
const adapterMember = createEntityAdapter<User>();  
const memberInitialState: MembersState = adapterMember.getInitialState({ total: 0 });

/* Define Members State */
interface TagsState extends EntityState<Tag> {
    saveLoading: boolean;
}
const adapterTag = createEntityAdapter<Tag>();  
const tagInitialState: TagsState = adapterTag.getInitialState({ saveLoading: false });

/* Ticket Tafs */
interface TicketTagsState extends EntityState<Tag> {
    saveLoading: boolean;
}
const adapterTicketTags = createEntityAdapter<Tag>();  
const tickettagsInitialState: TicketTagsState = adapterTag.getInitialState({ saveLoading: false });


// Define Settings State
export interface SettingsState{
    isSettingsLoaded: boolean,
    
    organizations: OrganizationsState,
    members: MembersState,
    subscription: Subscription;
    tags: TagsState;
    ticket_tags: TicketTagsState;

    selectedMemberId: number,
    saveMemberLoading: boolean;

    saveNotificationsLoading: boolean,
    notification_transcript: string,
    notification_offline: string,
    notification_newchat: string,
    notification_missed: string,
    notification_newticket: string,
    notification_ticketreplies: string,

    widget_languages: WidgetLanguage[],
    triggers: Trigger[],
    news: News[]
}
const initialState: SettingsState = {
    isSettingsLoaded: false,

    organizations: organizationInitialState,
    members: memberInitialState,
    subscription: null,
    tags: tagInitialState,
    ticket_tags: tickettagsInitialState,

    selectedMemberId: 0,
    saveMemberLoading: false,

    saveNotificationsLoading: false,
    notification_transcript: "",
    notification_offline: "",
    notification_newchat: "",
    notification_missed: "",
    notification_newticket: "",
    notification_ticketreplies: "",

    widget_languages: [],
    triggers: [],
    news: []
}

const reducer = createReducer(
    initialState,
    on(
        SettingsActions.AllSettingsLoaded,
        (state, {settings}) => ({ ...state, 
                organizations: adapterOrganization.setAll(settings.organizations, state.organizations),
                members: adapterMember.setAll(settings.members, state.members),
                tags: adapterTag.setAll(settings.tags, state.tags),
                ticket_tags: adapterTicketTags.setAll(settings.ticket_tags, state.ticket_tags),
                notification_transcript: settings.notification_transcript,
                notification_offline: settings.notification_offline,
                notification_missed: settings.notification_missed,
                notification_newchat: settings.notification_newchat,
                notification_newticket: settings.notification_newticket,
                notification_ticketreplies: settings.notification_ticketreplies,
                subscription: settings.subscription,
                widget_languages: settings.widget_languages,
                triggers: settings.triggers,
                news: settings.news,
                isSettingsLoaded: true
            })
    ),
    on(
        SettingsActions.AllOrganizationsLoaded,
        (state, {organizations}) => ({
            ...state,
            organizations:  adapterOrganization.addMany(organizations, state.organizations)
        })
    ),
    on(
        SettingsActions.AllMembersLoaded,
        (state, {members}) => ({
            ...state,
            members: adapterMember.addMany(members, state.members) 
        })
    ),
    /* Subscription Plans Reducers */
    on(
        SettingsActions.SwitchPlanSuccess,
        (state, {sub}) => ({
            ...state,
            subscription: sub
        })
    ),
    /* Notifications Reducers */
    on(
        SettingsActions.NotificationsSaveRequest,
        (state)  => ({...state, saveNotificationsLoading : true})
    ),
    on(
        SettingsActions.NotificationsSaveSuccess,
        (state, {settings}) => ({
            ...state,
            notification_transcript: settings.notification_transcript,
            notification_missed: settings.notification_missed,
            notification_newchat: settings.notification_newchat,
            notification_offline: settings.notification_offline,
            notification_newticket: settings.notification_newticket,
            notification_ticketreplies: settings.notification_ticketreplies,

            saveNotificationsLoading: false
        })
    ), 
    on(
        SettingsActions.NotificationsSaveFailed,
        (state)  => ({...state, saveNotificationsLoading : false})
    ),    
    /* Members Reducers  */
    on(
        SettingsActions.MemberSelected,
        (state, {member_id}) => ({...state, selectedMemberId : member_id})
    ),
    on(
        SettingsActions.MemberSave,
        (state) => ({...state, saveMemberLoading : true})
    ),
    on(
        SettingsActions.MemberDelete,
        (state) => ({...state, saveMemberLoading : true})
    ),    
    on(
        SettingsActions.MemberSaveSuccess,
        (state, {user}) => ({
            ...state,
            saveMemberLoading: false,
            members: adapterMember.upsertOne(user, state.members)
        })
    ),
    on(
        SettingsActions.MemberSaveFailed,
        (state) => ({...state, saveMemberLoading : false})
    ),
    on(
        SettingsActions.MemberDeleteSuccess,
        (state, {member_id}) => ({
            ...state,
            saveMemberLoading: false,
            members: adapterMember.removeOne(member_id, state.members)
        })
    ),
    
    on(
        SettingsActions.TagsSaveRequest,
        (state)  => ({...state, tags: {...state.tags, saveLoading: true  }})
    ),
    on(
        SettingsActions.TagsSaveSuccess,
        (state, {tags})  => ({...state, tags: adapterTag.setAll(tags, {...state.tags, saveLoading: false}) })
    ),
    on(
        SettingsActions.TagsSaveFailed,
        (state)  => ({...state, tags: {...state.tags, saveLoading: false  }})
    ),

    on(
        SettingsActions.TicketsTagsSaveRequest,
        (state)  => ({...state, ticket_tags: {...state.ticket_tags, saveLoading: true  }})
    ),
    on(
        SettingsActions.TicketsTagsSaveSuccess,
        (state, {tags})  => ({...state, ticket_tags: adapterTicketTags.setAll(tags, {...state.ticket_tags, saveLoading: false}) })
    ),
    on(
        SettingsActions.TicketsTagsSaveFailed,
        (state)  => ({...state, ticket_tags: {...state.ticket_tags, saveLoading: false  }})
    )
);

export function settingsReducer(state: SettingsState | undefined, action: Action) {
    return reducer(state, action);
}

//Expose the selectors
export const selectOrganizationState = (state: SettingsState) => state.organizations;
export const selectMemberState = (state: SettingsState) => state.members;
export const selectTagsState = (state: SettingsState) => state.tags;
export const selectTicketTagsState = (state: SettingsState) => state.ticket_tags;


export const { selectAll: selectAllOrganizations } = adapterOrganization.getSelectors();
export const { selectAll: selectAllMembers } = adapterMember.getSelectors();
export const { selectAll: selectAllTags } = adapterTag.getSelectors();
export const { selectAll: selectAllTicketTags } = adapterTicketTags.getSelectors();

