import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { MomentModule } from 'ngx-moment';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';
import { IdentityModule } from './core/identity/identity.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes.module';
import { AuthEffects, AuthService, RoleGuard } from './core/auth';
import { LayoutConfig } from './core/config/layout.config';
import { CoreModule } from './core/core.module';
import { metaReducers, reducers } from './core/core.state';
import { ErrorPageComponent } from './core/error-page/error-page.component';
import { ChatFlowsService } from './core/services/chatflows.service';
import { CoreService } from './core/services/core.service';
import { SavedRepliesService } from './core/services/saved.replies.service';
import { ChatFlowsEffects } from './core/store/chatflow.effects';
import { LayoutEffects } from './core/store/layout.effects';
import { PropertiesEffects } from './core/store/properties.effects';
import { SavedRepliesEffects } from './core/store/saved.replies.effects';
import { ToastrEffects } from './core/store/toastr.effects';
import {
	DataTableService, KtDialogService, LayoutConfigService,
	LayoutRefService, MenuAsideService, MenuConfigService,
	PageConfigService, SplashScreenService
} from './core/_base/layout';
import { FallbackComponent } from './fallback.component';
import { SettingsService } from './settings/services/settings.service';
import { SettingsEffects } from './settings/store/settings.effects';
import { ShouldLoginComponent } from './should-login.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from '../environments/environment';
import { KbEffects } from './kb/store/kb.effects';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { VisitorsEffects } from './conversations/store/visitors.effects';

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
	// initialize app by loading default demo layout config
	return () => {
		if (appConfig.getConfig() === null) {
			appConfig.loadConfigs(new LayoutConfig().configs);
		}
	};
}

@NgModule({
  declarations: [
	AppComponent,
	ErrorPageComponent,
	FallbackComponent,
    ShouldLoginComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
	BrowserModule,
	HttpClientModule,
	IdentityModule.forRoot(),
	ToastrModule.forRoot({
		positionClass: 'toast-bottom-center'
	}),
	NgxPermissionsModule.forRoot(),
	CoreModule,
	OverlayModule,
	StoreModule.forRoot(reducers, {metaReducers}),
	EffectsModule.forRoot([
		AuthEffects,
		SavedRepliesEffects, 
		ChatFlowsEffects, 
		PropertiesEffects, 
		ToastrEffects,
		LayoutEffects,
		/* Needed on the app.module and not on the settings.module
		since the settingseffects loads the properties key  */
		SettingsEffects,
		// need by both conversations module and live visitors module
		VisitorsEffects,
		KbEffects
	]),
	StoreRouterConnectingModule.forRoot({stateKey: 'router'}),
	StoreDevtoolsModule.instrument(),
	
	FormsModule,
	//AuthModule.forRoot(),
	NgbModule, NgbTypeaheadModule,
	TranslateModule.forRoot(),
	InlineSVGModule.forRoot({baseUrl: '/assets/media/icons/svg/'}),
	MonacoEditorModule.forRoot(),
	MomentModule,

	NgxGoogleAnalyticsModule.forRoot(environment.ga),
	NgxGoogleAnalyticsRouterModule,

	/* AppRouting needs to be the last after all other routings modules */
	AppRoutingModule 
  ],
  providers: [
    	/*{ 
			provide: HTTP_INTERCEPTORS, 
			useClass: AuthorizeInterceptor, 
			multi: true ,
			deps: [AuthorizeService, Router, Store]
		},*/
		AuthService,
		SettingsService,
		SavedRepliesService,
		ChatFlowsService,
		CoreService,
		LayoutConfigService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		KtDialogService,
		DataTableService,
		SplashScreenService,
		{
			// layout config initializer
			provide: APP_INITIALIZER,
			useFactory: initializeLayoutConfig,
			deps: [LayoutConfigService], multi: true
		},
	
		// template services
		MenuAsideService,
		RoleGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
