
import { Injectable } from '@angular/core';
import { CoreService } from './core.service';


@Injectable({
  providedIn: 'root'
})
export class LogService {

    constructor(private api: CoreService) {
    }

    debug(message: string){
        this.api.log({
            level: 1,
            message: message,
            exception: null
            });
        }

    info(message: string){
        this.api.log({
            level: 2,
            message: message,
            exception: null
          });
      }

    error(message: string, err){
        this.api.log({
            level: 3,
            message: message,
            exception: JSON.stringify(err, Object.getOwnPropertyNames(err))
            });
        }

    critical(message: string, err){
        this.api.log({
            level: 4,
            message: message,
            exception: JSON.stringify(err, Object.getOwnPropertyNames(err))
            });
    }

}