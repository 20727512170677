import { createAction, props } from '@ngrx/store';
import { ChatFlow } from '../../models/chatflow.model';

export const ChatFlowsRequested = createAction(
    '[Core] Chat Flows Requested',
    props<{organization_id: number;}>(),
)

export const ChatFlowsLoaded = createAction(
    '[Core] Chat Flows Loaded',
    props<{ChatFlows: ChatFlow[];}>(),
)

export const ChatFlowSaveFailed = createAction(
    '[Core] Chat Flows Failed Saving',
    props<{message: string;}>()
)

export const ChatFlowSaveRequested = createAction(
    '[Core] Chat Flows Save Requested',
    props<{ChatFlow: ChatFlow[];}>(),
)

export const ChatFlowDeleteRequested = createAction(
    '[Core] Chat Flows Delete Requested',
    props<{id: number;}>(),
)

export const ChatFlowDeleteSucess = createAction(
    '[Core] Chat Flows Delete Success',
    props<{id: number}>(),
)

export const ChatFlowDeleteFailed = createAction(
    '[Core] Chat Flows Delete Failed',
    props<{message: string;}>()
)

export const ChatFlowSaveSucess = createAction(
    '[Core] Chat Flows Save Success',
    props<{ChatFlow: ChatFlow[];}>(),
)

export const SaveLoading = createAction(
    '[Core] Chat Flows Save Loading',
    props<{isLoading: boolean;}>(),
)

export const DeleteLoading = createAction(
    '[Core] Chat Flows Delete Loading',
    props<{isLoading: boolean;}>(),
)