
import { ToastrService } from 'ngx-toastr';
import * as ToastrActions  from './toastr.actions';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ToastrEffects {

    showSuccess = createEffect(() => this.actions$.pipe(
        ofType(ToastrActions.showToastrSuccess),
        tap( s => this.toastr.success( s.message, s.title, {onActivateTick: true} ))
    ), {dispatch: false});

    showError = createEffect(() => this.actions$.pipe(
        ofType(ToastrActions.showToastrError),
        tap( s => this.toastr.error( s.message, s.title, {onActivateTick: true} ))
    ), {dispatch: false});

    showInfo = createEffect(() => this.actions$.pipe(
        ofType(ToastrActions.showToastrInfo),
        tap( s => this.toastr.info( s.message, s.title, {onActivateTick: true} ))
    ), {dispatch: false});

    showPopup = createEffect(() => this.actions$.pipe(
        ofType(ToastrActions.showToastrPopup),
        tap( s => this.modalService.open(s.message, { centered: true, windowClass: 'charla-dialog'}) )
    ), {dispatch: false});

    constructor(
        private actions$: Actions,
        private toastr: ToastrService,
        private modalService: NgbModal
    ) { }

}