import { createFeatureSelector, createSelector } from "@ngrx/store";
import { KbState } from "./kb.reducers";
import * as fromKb from './kb.reducers';


export const selectKbState = createFeatureSelector<KbState>('kb');
export const selectArticlesState = createSelector(selectKbState, fromKb.selectArticlesState);
export const selectCategoriesState = createSelector(selectKbState, fromKb.selectCategoriesState);

export const selectAllArticles = createSelector(selectArticlesState, fromKb.selectAllArticles);

export const selectAllCategories = createSelector(
    selectCategoriesState, fromKb.selectAllCategories
);

export const selectCategoriesByPropertyId = ({id}) => createSelector(
    selectAllCategories,
    categories => categories.filter(c => c.property_id == id)
);

export const selectArticlesByPropertyId = ({id}) => createSelector(
    selectAllArticles,
    articles => articles.filter(c => c.property_id == id)
);

export const selectArticleById = ({id}) => createSelector(
    selectArticlesState,
    articles => articles.entities[id]
);

export const selectArticleSaveLoading = createSelector(
    selectArticlesState,
    articles => articles.save_loading
);

export const selectArticleDeleteLoading = createSelector(
    selectArticlesState,
    articles => articles.delete_loading
);

export const selectArticleUploadLoading = createSelector(
    selectArticlesState,
    articles => articles.upload_loading
);

export const selectCategoryById = ({id}) => createSelector(
    selectCategoriesState,
    categories => categories.entities[id]
);

export const selectCategorySaveLoading = createSelector(
    selectCategoriesState,
    category => category.save_loading
);

export const selectCategoryDeleteLoading = createSelector(
    selectCategoriesState,
    category => category.delete_loading
);

export const selectKbSaveLoading = createSelector(
    selectKbState,
    kb => kb.save_loading
);