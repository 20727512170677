import { Subscription } from 'rxjs';
// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from './core/_base/layout';
// language list
import { locale as enLang } from './core/config/i18n/en';
import { IdentityService } from './core/identity/identity.service';
import * as AuthActions from './core/auth/auth.actions';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from './core/core.state';

@Component({
  selector: 'body[app-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
	// Public properties
	title = 'Charla';
	loader: boolean;
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param translationService: TranslationService
	 * @param router: Router
	 * @param layoutConfigService: LayoutCongifService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(private translationService: TranslationService,
				private router: Router,
				private layoutConfigService: LayoutConfigService,
				private splashScreenService: SplashScreenService,
				private authService: IdentityService,
				private store: Store<AppState>) {

		// register translations
		this.translationService.loadTranslations(enLang);
	
		console.debug("AppComponent is constructed");

		// right solution was to put the runLoginSequence in the base.component
		// however it never get fires because of the AuthGuards in the child routes 
		// @see https://stackoverflow.com/questions/49428112/canactivate-guards-on-child-routes-run-before-parent-resolve-finish
		let auth_routes = [
            '/auth/login', '/auth/register', '/auth/forgot-password',
			'/auth/logout', '/error', '/should-login', '/externalshop', '/externalmessenger'
		];
        if ( !auth_routes.some(route => window.location.pathname.includes(route))) {
            this.authService.runInitialLoginSequence();
        } else {
			console.debug("Identity pages, skipping runInitialLoginSequence");
		}

		this.authService.canActivateProtectedRoutes$.pipe(
			filter(s => s),
			take(1)
		).subscribe(s => {
			console.debug("User is signed in, getting the user profile");
			this.store.dispatch(AuthActions.UserRequested());
		});

	}


	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		// enable/disable loader
		this.loader = this.layoutConfigService.getConfig('loader.enabled');

		const routerSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				// hide splash screen
				//this.splashScreenService.hide();

				// scroll to top on every route change
				window.scrollTo(0, 0);

				// to display back the body content
				setTimeout(() => {
					document.body.classList.add('kt-page--loaded');
				}, 500);
			}
		});
		this.unsubscribe.push(routerSubscription);
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}
}
