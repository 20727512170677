import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { combineLatest, empty, EMPTY, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom, tap, take, skipWhile, skipUntil } from 'rxjs/operators';
import { AppState } from '../../core/core.state';
import * as ToastrActions from '../../core/store/toastr.actions';
import * as LayoutActions from '../../core/store/layout.actions';
import { ConversationsService } from '../../core/services/conversations.service';
import * as VisitorsActions from './visitors.actions';
import { allVisitorsLoaded, selectVisitorById, selectCurrentVisitor, selectSelectedAgent } from './visitors.selectors';
import { Update } from '@ngrx/entity';
import { Visitor } from '../../models/visitor.model';
import { Router } from '@angular/router';
import { currentUser } from '../../core/auth';
import { selectViewMode, selectSelectedProperty, selectOpenVisitor, selectMineVisitor, selectClosedVisitor } from '../../core/store/layout.selectors';
import { currentProperties } from 'src/app/core/auth/auth.selectors';
import { isNil } from 'lodash-es';


@Injectable()
export class VisitorsEffects {

    loadAllVisitors$ = createEffect(() => this.actions$.pipe(
        ofType(VisitorsActions.AllVisitorsRequested),
        switchMap(action => combineLatest([
            of(action),
            this.store.pipe( select(currentProperties), filter(p => !isNil(p) ), take(1)),
            this.store.pipe( select(selectViewMode), take(1)),
            this.store.pipe( select(selectSelectedAgent), filter(a => !isNil(a) ), take(1)),
            this.store.pipe( select(selectSelectedProperty), take(1)),
        ])),
        switchMap( ([action, properties, view_mode, agent, property_id]) => {
            var p = (property_id != 0) ? property_id : properties.map(q => q.id).join(',');   
            return this.converseService.getVisitors( p, view_mode, agent.id, action.paging.page)
        }),
        map(vs => VisitorsActions.AllVisitorsRequestSuccess({visitors: vs}) )
    ));

    loadVisitor$ = createEffect(() => this.actions$.pipe(
        ofType(VisitorsActions.VisitorRequested),
        mergeMap( action => this.converseService.getVisitor( action.visitor_id )),
        map( (res) => 
            VisitorsActions.VisitorRequestSuccess({ visitor: res.model})
            //SessionsForVisitorLoaded({Sessions: res.model.sessions} )
        )
    ));

    /** 
     * When the visitor is selected we want to 
     * 1. load the extra info
     * 2. flush the unread count
     * 3. focus on the message box 
     */
    visitorSelected$ = createEffect(() => this.actions$.pipe(
        ofType(VisitorsActions.VisitorSelected), 
        mergeMap(action => this.converseService.getVisitor(action.visitor_id) ),
        withLatestFrom(this.store.select(selectViewMode)),
        switchMap( ([res,view_mode]) => {
            const visitor_update: Update<Visitor> = {
                id: res.model.id,
                changes: {
                    ...res.model,
                    unread_count: 0
                }
            };
            return [
                VisitorsActions.VisitorUpdateSuccess({ visitor: visitor_update}),
                LayoutActions.FocusMessageBox(),
                LayoutActions.persistVisitorSelection({ visitor_id: res.model.id, 
                    view: view_mode})
                
            ];
        })
    ));

    /**
     * Whenever the new visitor list is done loading, preselect the right visitor
     * depending on the current view mode
     */
    visitorsLoaded$ = createEffect(() => this.actions$.pipe(
        ofType(VisitorsActions.AllVisitorsRequestSuccess),
        withLatestFrom( this.store.select(selectViewMode) ,
                        this.store.select(selectOpenVisitor),
                        this.store.select(selectMineVisitor),
                        this.store.select(selectClosedVisitor)),
        
        filter( ([action, view_mode, open, mine,closed]) => 
            // filter if no vid is selected in any view
            ( [open,mine,closed].some(q => (q !== "") ) )  
        ),
        map( ([action, view_mode, open, mine,closed]) => {

            let vid = '';
            if (view_mode == 'open') vid = open;
            else if (view_mode == 'agent') vid = mine;
            else if (view_mode == 'closed') vid = closed;

            return VisitorsActions.VisitorSelected({visitor_id: vid})
                
        }) 
    ));

    /** Live Visitors */
    visitorInitiateConversation$ = createEffect(() => this.actions$.pipe(
        ofType(VisitorsActions.VisitorInitiateConversation),
        tap( () => this.router.navigateByUrl('/conversations')),
        switchMap(action => combineLatest([
            of(action),
            this.store.pipe(select(selectVisitorById(action.visitor_id)), take(1)),
            // wait for the visitor loaded event, otherwise the new visitor will get overwritten
            this.store.pipe(select(allVisitorsLoaded), filter(loaded => loaded))
        ])),
        switchMap( ([action, v, is_all_visitors_loaded]) => {

            if (v == null){
                /* @todo redundant since it will make two same http requests */
                return [
                    VisitorsActions.VisitorRequested({visitor_id: action.visitor_id }),
                    VisitorsActions.VisitorSelected({visitor_id: action.visitor_id})
                ];
            } else {
                return [VisitorsActions.VisitorSelected({visitor_id: action.visitor_id})];
            }

        }
        
        )
    ));

    saveVisitorInfo$ = createEffect(() => this.actions$.pipe(
        ofType(VisitorsActions.VisitorInfoSaveRequest),
        mergeMap( action => 
            this.converseService.saveVisitorInfo(action.visitor.id, action.visitor.changes).pipe(
                switchMap (res => {
                    const visitor_update: Update<Visitor> = {
                        id: res.model.id,
                        changes: {
                            ...res.model
                        }
                    };
                    return [
                        VisitorsActions.VisitorInfoSaveSuccess({visitor: visitor_update}),
                        ToastrActions.showToastrSuccess({message: 'Visitor details were updated successfully.' , title: 'Visitor Updated'})
                    ]
                }),
                catchError((er: HttpErrorResponse) => of(
                    ToastrActions.showToastrError({message: 'Problems while updating the visitor info. The error has been reported.' , title: 'Something went wrong'})
                ))
            )
        )
    ));

    /*setSelectedAgent = createEffect(() => this.actions$.pipe(
        ofType(VisitorsActions.SetSelectedAgent),
        map( () => VisitorsActions.AllVisitorsRequested( {paging: { page: 0 } } ))  
    ));*/
       
    constructor(
        private converseService: ConversationsService,
        private actions$: Actions,
        private store: Store<AppState>,
        private router: Router
    ) { }
}
