
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { IdentityService } from '../identity/identity.service';
import { environment } from '../../../environments/environment';
import { AllSettingsRequested } from '../../settings/store/settings.actions';
import { AppState } from '../core.state';
import * as ToastrActions from '../store/toastr.actions';
import * as AuthActions from './auth.actions';
import { currentOrganization } from './auth.selectors';
import { AuthService } from './auth.service';


@Injectable()
export class AuthEffects{

    login$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.Login),
        map( (action) => {
            localStorage.setItem(environment.authTokenKey, action.authToken);
            return AuthActions.UserRequested();
        }),
    ));

    register$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.Register),
        map(action => {
            localStorage.setItem(environment.authTokenKey, action.authToken);
            return AuthActions.UserRequested();
        })
    ));
    
    logout$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.Logout),
        mergeMap(() => 
            this.auth.logout().pipe(
                catchError(er => of(
                    ToastrActions.showToastrError({message: 'Problems logging out.' , title: 'Something went wrong'})
                ))
            )
        ),
        tap(() => {
            //localStorage.removeItem(environment.authTokenKey);
            //this.auth.logout().subscribe();
            // replace hardcoded urls
			//this.router.navigate(['/auth/login'], {queryParams: {returnUrl: this.returnUrl}});
            
            this.identityService.logout();
            /*this.router.navigate(
                [ApplicationPaths.LogOut,], 
                {   state: {local: true}, 
                    queryParams: {returnUrl: '/authentication/login'} 
                });*/
        })
    ), {dispatch: false});



    // @todo needs refactoring
    loadUser$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.UserRequested),
        mergeMap( () => 
            this.auth.getUserByToken().pipe(
                switchMap(user => [
                    AuthActions.UserLoaded({ user: user})
                ]),
                catchError(er => of(
                    AuthActions.Logout(),
                    ToastrActions.showToastrError({
                        message: 'No organization is currently assigned to the user' , 
                        title: 'Something went wrong'
                    })
                ))
            ),
        )
      ));

      updateProfile$ = createEffect(() => this.actions$.pipe(
          ofType(AuthActions.UserSaveRequest),
          withLatestFrom(this.store.pipe(select(currentOrganization))),
          mergeMap( ([action, org]) => {
            let profile = Object.assign(
                    {}, 
                    action.user, 
                    { organization_id: org.id}
                );
            return this.auth.update_profile(profile).pipe(
                switchMap(res => [
                   AuthActions.UserSaveSuccess({ user: res.model }),
                   ToastrActions.showToastrSuccess( {message:'Profile was successfully updated.', title: ''}),   
                   /* We need here to update the entire settings, since a field like the photo
                      need to be reflected as well */
                    AllSettingsRequested()
                ]),
                catchError((error: HttpErrorResponse) => of(
                    AuthActions.UserSaveFailed({message: error.error}),
                    ToastrActions.showToastrError({message: error.error , title: 'Something went wrong'})
                ))
            );
        })
    ));


    updateAvailability$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.UpdateAvailabilityRequest),
        withLatestFrom(this.store.pipe(select(currentOrganization))),
        mergeMap( ([action, org]) => this.auth.updateAvailability(org.id, action.availability).pipe(
            switchMap(res => [
               AuthActions.UpdateAvailabilitySuccess({ availability: res.model.available }),
            ]),
            catchError((error: HttpErrorResponse) => of(
                ToastrActions.showToastrError({message: 'Availability was not updated. Please try again.' , title: 'Something went wrong'})
            ))
        ))
    ));


    private returnUrl: string;

    constructor(private actions$: Actions,
        private router: Router,
        private auth: AuthService,
        private identityService: IdentityService,
        private store: Store<AppState>) {

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.returnUrl = event.url;
			}
		});
	}
}
