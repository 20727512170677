import { createAction, props } from '@ngrx/store';
import { ProfileUpload, User } from '../../models';

export const Login = createAction(
    '[Login] Action',
    props<{authToken: string }>()
)

export const Logout = createAction(
    '[Logout] Action'
)

export const Register = createAction(
    '[Register] Action',
    props<{authToken: string }>()
)

export const UserRequested = createAction(
    '[Request User] Action',
)

export const UserLoaded = createAction(
    '[Load User] Auth API',
    props<{user: User }>()
)

export const UserSaveRequest = createAction(
    '[Settings] Save User',
    props<{user: ProfileUpload }>()
)

export const UserSaveSuccess = createAction(
    '[Settings] Save User Success',
    props<{user: User }>()
)

export const UserSaveFailed = createAction(
    '[Settings] Save User Failed',
    props<{message: string }>()
)

export const UserSaveLoading = createAction(
    '[Settings] Saving User',
    props<{isLoading: boolean }>()
)

export const UpdateAvailabilityRequest = createAction(
    '[User] Update Availability Request',
    props<{availability: number }>()
)

export const UpdateAvailabilitySuccess = createAction(
    '[User] Update Availability Success',
    props<{availability: number }>()
)
