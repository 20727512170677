import { props, createAction } from '@ngrx/store'
import { KbArticle } from '../models/kbarticle.model'
import { Kb } from '../models/kb.model'
import { Update } from '@ngrx/entity'
import { KbCategory } from '../models/kbcategory.model'

export const KbRequest = createAction(
    '[Knowledge Base] Request'
)

export const KbRequestSuccess = createAction(
    '[Knowledge Base] Request Success',
    props<{Kb: Kb}>()
)

export const KbSaveRequest = createAction(
    '[Knowledge Base] Save Settings Request',
    props<{kb: Kb}>()
)

export const KbSaveSuccess = createAction(
    '[Knowledge Base] Save Settings Success',
    props<{kb: Kb}>()
)

export const KbSaveFailed = createAction(
    '[Knowledge Base] Save Settings Failed',
    props<{message: string}>()
)

export const KbArticleRequest = createAction(
    '[Knowledge Base] Get Article By Id',
    props<{article_id: number}>()
)

export const KbArticleRequestSuccess = createAction(
    '[Knowledge Base] Get Article By Id Success',
    props<{article: Update<KbArticle>}>()
)

export const KbArticleSaveRequest = createAction(
    '[Knowledge Base] Article Update New or Existing Save',
    props<{article: KbArticle}>()
)

export const KbArticleSaveSuccess = createAction(
    '[Knowledge Base] Article Update New or Existing Save Success',
    props<{article: KbArticle}>()
)

export const KbArticleSaveFailed = createAction(
    '[Knowledge Base] Article Save Failure',
    props<{message: string}>()
)

export const KbArticleDeleteRequest = createAction(
    '[Knowledge Base] Delete Article By Id',
    props<{article_id: number}>()
)

export const KbArticleDeleteSuccess = createAction(
    '[Knowledge Base] Delete Article By Id Success',
    props<{article_id: number}>()
)

export const KbArticleDeleteFailed = createAction(
    '[Knowledge Base] Article Delete Failure',
    props<{message: string}>()
)

export const KbArticleUploadInProgress = createAction(
    '[Knowledge Base] Tiny MCE Image Upload Progress',
    props<{status: boolean}>()
)

export const KbCategorySaveRequest = createAction(
    '[Knowledge Base] Cateogry Update New or Existing Save',
    props<{category: KbCategory}>()
)

export const KbCategorySaveSuccess = createAction(
    '[Knowledge Base] Cateogry Update New or Existing Save Success',
    props<{category: KbCategory}>()
)

export const KbCategorySaveFailed = createAction(
    '[Knowledge Base] Cateogry Save Failure',
    props<{message: string}>()
)

export const KbCategoryDeleteRequest = createAction(
    '[Knowledge Base] Delete Cateogry By Id',
    props<{category_id: number}>()
)

export const KbCategoryDeleteSuccess = createAction(
    '[Knowledge Base] Delete Cateogry By Id Success',
    props<{category_id: number}>()
)

export const KbCategoryDeleteFailed = createAction(
    '[Knowledge Base] Cateogry Delete Failure',
    props<{message: string}>()
)

