import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagEditorComponent } from './tag-editor.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [TagEditorComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbDropdownModule
  ],
  exports: [TagEditorComponent]
})
export class TagEditorModule { }
