import { createReducer, on, Action, State } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { SavedReply } from '../../models/saved.reply.model';
import { SavedRepliesLoaded, SavedReplySaveSucess } from './saved.replies.actions';
import * as SavedRepliesActions from './saved.replies.actions'

export interface SavedRepliesState extends EntityState<SavedReply> {
    selected_saved_reply: number;
    deleteLoading: boolean;
    saveLoading: boolean;
}
const adapter = createEntityAdapter<SavedReply>({
    sortComparer: sortById
});
const initialState: SavedRepliesState = adapter.getInitialState({ 
    selected_saved_reply: 0,
    deleteLoading: false,
    saveLoading: false
});

const reducer = createReducer(
    initialState,
    on(
        SavedRepliesActions.SavedRepliesLoaded,
        (state, { SaveReplies }) => ( adapter.setAll(
                                        SaveReplies, 
                                        {...state, selected_saved_reply:0}
                                    ))
    ),
    /* Save actions */
    on(
        SavedRepliesActions.SavedReplySaveRequested,
        (state) => ({...state, saveLoading: true}) 
    ),
    on(
        SavedRepliesActions.SavedReplySaveSucess,
        (state, { SavedReply }) => (adapter.upsertOne(SavedReply, {...state, saveLoading: false}))
    ),
    on(
        SavedRepliesActions.SavedReplySaveFailed,
        (state) => ({...state, saveLoading: false}) 
    ),
    /* Delete actions */
    on(
        SavedRepliesActions.SavedReplyDeleteRequested,
        (state, { id }) =>({...state, deleteLoading: true}) 
    ),
    on(
        SavedRepliesActions.SavedReplyDeleteSucess,
        (state, { id }) => (adapter.removeOne(id, { ...state, deleteLoading: false} ))
    ),
    on(
        SavedRepliesActions.SavedReplyDeleteFailed,
        (state) =>  ({...state, deleteLoading: false}) 
    )
);
 
export function savedRepliesReducer(state: SavedRepliesState | undefined, action: Action) {
    return reducer(state, action);
}

export function sortById(a: SavedReply, b: SavedReply): number {
    return (a.id > b.id) ? -1 : 1;
  }

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal  
} = adapter.getSelectors();
