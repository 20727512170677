
import { createSelector } from '@ngrx/store';

export const selectAuthState = state => state.auth;

export const isLoggedIn = createSelector(
    selectAuthState,
    auth => auth.loggedIn
);

export const isLoggedOut = createSelector(
    isLoggedIn,
    loggedIn => !loggedIn
);


export const currentAuthToken = createSelector(
    selectAuthState,
    auth => auth.authToken
);

export const isUserLoaded = createSelector(
    selectAuthState,
    auth => auth.isUserLoaded
);

export const currentUser = createSelector(
    selectAuthState,
    auth => auth?.user
);


export const currentOrganization = createSelector(
    selectAuthState,
    auth => auth.currentOrganization
);

export const currentAvailableStatus = createSelector(
    currentUser,
    user => user?.available
);

export const selectUserSaveLoading = createSelector(
    selectAuthState,
    auth => auth.saveLoading
);

export const currentUserRoleIds = createSelector(
    currentUser,
    user => {
        if (!user) {
            return [];
        }

        return user.roles;
    }
);

export const currentProperties = createSelector(
    currentUser,
    user => user?.properties
);



