import { createSelector, createFeatureSelector, props } from '@ngrx/store';
import * as fromVisitor from './visitors.reducers';
import { VisitorState } from './visitors.reducers';
import { selectSettingsState } from 'src/app/settings/store/settings.selectors';

export const selectVisitorsState = createFeatureSelector<VisitorState>('visitors');


export const allVisitorsLoaded = createSelector(
    selectVisitorsState,
    messageState => messageState.isAllVisitorsLoaded
);

export const selectVisitorById = (visitor_id: string) => createSelector(
    selectVisitorsState,
    visitorState => visitorState.entities[visitor_id]
);

export const selectAllVisitors = createSelector(
    selectVisitorsState,
    fromVisitor.selectAll
);

export const selectVisitorsByPropertyId = createSelector(
    selectAllVisitors,
    (vs, props) => vs.filter(
        v =>  ( (props.property_id == 0) || (v.property_id == props.property_id) )
    )
);

export const selectVisitorsByCurrentPropertyId = createSelector(
    selectAllVisitors,
    (vs, props) => vs.filter(
        v =>  ( (props.property_id == 0) || (v.property_id == props.property_id) )
    )
);


export const selectCurrentVisitor = createSelector(
    selectVisitorsState,
    visitorState => visitorState.selectedVisitorId
);

export const selectVisitorsLoading = createSelector(
    selectVisitorsState,
    visitorState => visitorState.isVisitiorsLoading
);

export const selectSelectedVisitor = createSelector(
    selectVisitorsState,
    visitorState => visitorState.entities[visitorState.selectedVisitorId]
);

export const selectSelectedAgent = createSelector(
    selectVisitorsState,
    selectSettingsState,
    (visitorState, settingsState) => settingsState.members.entities[visitorState.selectedAgentId]
);

