import { createAction, props } from '@ngrx/store'

export const showToastrSuccess = createAction(
    '[Core] Show Toastr Success',
    props<{message: string, title: string }>(),
)

export const showToastrError = createAction(
    '[Core] Show Toastr Error',
    props<{message:string,  title:string}>(),
)

export const showToastrInfo = createAction(
    '[Core] Show Toastr Info',
    props<{message:string,  title:string}>(),
)

export const showToastrPopup = createAction(
    '[Core] Show Toastr Popup',
    props<{message:string,  title:string}>(),
)