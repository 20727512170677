import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import * as KbActions from './kb.actions'
import { KbArticle } from '../models/kbarticle.model';
import { KbCategory } from '../models/kbcategory.model';
import { SettingsState } from '../../settings/store/settings.reducers';

const adapterArticles = createEntityAdapter<KbArticle>();
export interface KbArticlesState extends EntityState<KbArticle> {
    save_loading: boolean;
    delete_loading: boolean;
    upload_loading: boolean;
}
const articlesInitialState: KbArticlesState = adapterArticles.getInitialState({ 
    save_loading: false,
    delete_loading: false,
    upload_loading: false
});

const adapterCategories = createEntityAdapter<KbCategory>();
export interface KbCategoriesState extends EntityState<KbCategory> {
    save_loading: boolean;
    delete_loading: boolean;
}
const categoriesInitialState: KbCategoriesState = adapterCategories.getInitialState({ 
    save_loading: false,
    delete_loading: false
});


export interface KbState {
    articles: KbArticlesState,
    categories: KbCategoriesState,
    save_loading: boolean
}
const initialState: KbState = {
    articles: articlesInitialState,
    categories: categoriesInitialState,
    save_loading: false
}

const reducer = createReducer(
    initialState,
    on(
        KbActions.KbRequestSuccess,
        (state, { Kb }) => ({
            articles: adapterArticles.setAll(Kb.articles, state.articles),
            categories: adapterCategories.setAll(Kb.categories, state.categories),
            save_loading: false
        })
    ),
    on(
        KbActions.KbSaveRequest,
        (state, { kb }) => ({  ...state,  save_loading: true })
    ),
    on(
        KbActions.KbSaveSuccess,
        (state, { kb }) => ({  
            ...state,  
            save_loading: false 
        })
    ),
    on(
        KbActions.KbSaveFailed,
        (state, { message }) => ({  ...state,  save_loading: false })
    ),
    /* Articles */
    on(
        KbActions.KbArticleRequestSuccess,
        (state, { article }) => ({
            ...state,
            articles: adapterArticles.updateOne( article , state.articles)
        })
    ),
    on(
        KbActions.KbArticleSaveRequest,
        (state, { article }) => ({
            ...state,
            articles: {...state.articles, save_loading: true}
        })
    ),
    on(
        KbActions.KbArticleSaveSuccess,
        (state, { article }) => ({
            ...state,
            articles: adapterArticles.upsertOne( article , {...state.articles, save_loading: false})
        })
    ),
    on(
        KbActions.KbArticleSaveFailed,
        (state, { message }) => ({
            ...state,
            articles: {...state.articles, save_loading: false}
        })
    ),
    on(
        KbActions.KbArticleDeleteRequest,
        (state, { article_id }) => ({
            ...state,
            articles: {...state.articles, delete_loading: true}
        })
    ),
    on(
        KbActions.KbArticleDeleteSuccess,
        (state, { article_id }) => ({
            ...state,
            articles: adapterArticles.removeOne( article_id , {...state.articles, delete_loading: false})
        })
    ),
    on(
        KbActions.KbArticleDeleteFailed,
        (state, { message }) => ({
            ...state,
            articles: {...state.articles, delete_loading: false}
        })
    ),
    on(
        KbActions.KbArticleUploadInProgress,
        (state, { status }) => ({
            ...state,
            articles: {...state.articles, upload_loading: status}
        })
    ),
    /* Categories */
    on(
        KbActions.KbCategorySaveRequest,
        (state, { category }) => ({
            ...state,
            categories: {...state.categories, save_loading: true}
        })
    ),
    on(
        KbActions.KbCategorySaveSuccess,
        (state, { category }) => ({
            ...state,
            categories: adapterCategories.upsertOne( category , {...state.categories, save_loading: false})
        })
    ),
    on(
        KbActions.KbCategorySaveFailed,
        (state, { message }) => ({
            ...state,
            categories: {...state.categories, save_loading: false}
        })
    ),
    on(
        KbActions.KbCategoryDeleteRequest,
        (state, { category_id }) => ({
            ...state,
            categories: {...state.categories, delete_loading: true}
        })
    ),
    on(
        KbActions.KbCategoryDeleteSuccess,
        (state, { category_id }) => ({
            ...state,
            categories: adapterCategories.removeOne( category_id , {...state.categories, delete_loading: false})
        })
    ),
    on(
        KbActions.KbCategoryDeleteFailed,
        (state, { message }) => ({
            ...state,
            categories: {...state.categories, delete_loading: false}
        })
    ),
);

export function KbStateReducer(state: KbState | undefined, action: Action) {
    return reducer(state, action);
}

export const selectArticlesState = (state: KbState) => state.articles;
export const selectCategoriesState = (state: KbState) => state.categories;


export const { selectAll: selectAllArticles} = adapterArticles.getSelectors();
export const { selectAll: selectAllCategories} = adapterCategories.getSelectors();
