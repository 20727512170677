
<div class="kt-grid kt-grid--ver kt-grid--root charla-error">

	<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v5">

		<div class="kt-error_container">
			<div class="kt-error_title">
				<h1>{{title}}</h1>
			</div>
			<p class="kt-error_subtitle">
				{{subtitle}}
			</p>
			<p class="kt-error_description" [innerHTML]="desc"></p>
		</div>

</div>

</div>

