import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import * as ChatFlowsActions from './chatflow.actions'
import { ChatFlow } from '../../models/chatflow.model';

export interface ChatFlowsState extends EntityState<ChatFlow> {
    selected_chat_flow_group: number;
    deleteLoading: boolean;
    saveLoading: boolean;
}
const adapter = createEntityAdapter<ChatFlow>({
    sortComparer: sortById
});
const initialState: ChatFlowsState = adapter.getInitialState({ 
    selected_chat_flow_group: 0,
    deleteLoading: false,
    saveLoading: false
});

const reducer = createReducer(
    initialState,
    on(
        ChatFlowsActions.ChatFlowsLoaded,
        (state, { ChatFlows }) => ( adapter.setAll(
                                        ChatFlows, 
                                        {...state, selected_saved_reply:0}
                                    ))
    ),
    on(
        ChatFlowsActions.ChatFlowSaveRequested,
        (state) => ({...state, saveLoading: true})
    ),
    on(
        ChatFlowsActions.ChatFlowSaveSucess,
        (state, { ChatFlow }) => (
            adapter.upsertMany(ChatFlow, 
                adapter.removeMany( cf => cf.group_id == ChatFlow[0].group_id, 
                {...state, saveLoading: false})
            )
        )
    ),
    on(
        ChatFlowsActions.ChatFlowSaveFailed,
        (state) => ({...state, saveLoading: false})
    ),
    on(
        ChatFlowsActions.ChatFlowDeleteRequested,
        (state) => ({...state, deleteLoading: true})
    ),   
    on(
        ChatFlowsActions.ChatFlowDeleteSucess,
        (state, { id }) => (adapter.removeMany( 
            cf => cf.group_id == id, {...state, deleteLoading: false}))
    ),
    on(
        ChatFlowsActions.ChatFlowDeleteFailed,
        (state) => ({...state, deleteLoading: false})
    )
);
 
export function chatFlowsReducer(state: ChatFlowsState | undefined, action: Action) {
    return reducer(state, action);
}

export function sortById(a: ChatFlow, b: ChatFlow): number {
    return (a.id > b.id) ? -1 : 1;
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal  
} = adapter.getSelectors();
