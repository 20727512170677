import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Visitor, VisitorsResponse } from '../../models';
import { Paging } from '../../models/page.model';

export const AllVisitorsRequested = createAction(
    '[Visitor] Visitors Requested',
    props<{paging: Paging}>()
)

export const AllVisitorsRequestSuccess = createAction(
    '[Visitor] All Visitor Request Success',
    props<{visitors: VisitorsResponse}>(),
)

export const AllVisitorsReset = createAction(
    '[Visitor] Visitor Reset'
)

export const VisitorRequested = createAction(
    '[Visitor] Visitor Requested',
    props<{visitor_id: string}>(),
)

export const VisitorRequestSuccess = createAction(
    '[Visitor] Visitor Request Success',
    props<{visitor: Visitor}>(),
)

export const VisitorUpdateSuccess = createAction(
    '[Visitor] Visitor Update Loaded',
    props<{visitor: Update<Visitor>}>()
)

export const VisitorInitiateConversation = createAction(
    '[Visitor] Initiate Visitor Conversation',
    props<{visitor_id: string}>()
)

export const VisitorSelected = createAction(
    '[Visitor] Visitor Selected',
    props<{visitor_id: string}>(),
)

export const VisitorRemove = createAction(
    '[Visitor] Visitor Remove',
    props<{visitor_id: string}>(),
)

export const VisitorCurrentMessageSave = createAction(
    '[Visitor] Save Current Visitor Message',
    props<{visitor: Update<Visitor>}>(),
)

export const VisitorScrollPosition = createAction(
    '[Visitor] Save Current Visitor Scroll Position',
    props<{visitor: Update<Visitor>}>()
)

export const VisitorInfoSaveRequest = createAction(
    '[Visitor] Save Visitor Info Request',
    props<{visitor: Update<Visitor>}>(),
)

export const VisitorInfoSaveSuccess = createAction(
    '[Visitor] Save Visitor Info Success',
    props<{visitor: Update<Visitor>}>()
)

export const VisitorUnreadCount = createAction(
    '[Visitor] Increase Single Visitor Unread Messages Count',
    props<{visitor: Update<Visitor>}>(),
)

export const VisitorTyping = createAction(
    '[Visitor] Typing Event',
    props<{visitor_id: string, is_typing: boolean}>(),
)

export const SetSelectedAgent = createAction(
    '[Visitor] Set Selected Agent Id',
    props<{agent_id: string}>(),
)

export const SetPageNumber = createAction(
    '[Visitor] Set Current Page Number',
    props<{page_number: number}>(),
)




