import { EntityState, createEntityAdapter, Update } from '@ngrx/entity';
import { Activity } from '../../models';
import { createReducer, on, Action } from '@ngrx/store';
import * as ActivitiesActions from './activity.actions'

export interface ActivityState extends EntityState<Activity> {}

const adapter = createEntityAdapter<Activity>({
    selectId: (a => a.activity_id)
});

const initialState: ActivityState = adapter.getInitialState({ 
});

const reducer = createReducer(
    initialState,
    on(
        ActivitiesActions.ActivitiesOfVisitorLoaded,
        (state, { Activities }) => ( adapter.addMany(
                                        Activities,
                                        state
                                    ))
    ),
    on(
        ActivitiesActions.VisitLoaded,
        (state, { Visit }) => (adapter.upsertOne( {
            activity_id: Visit.id.toString(),
            content: Visit.url,
            activity_type: 'visit',
            activity_source: '',
            visitor_id: Visit.visitor_id,
            created_at: Visit.created_at
        } , state))

    ),
    on(
        ActivitiesActions.MessageActivityLoaded,
        (state, { Message }) => (adapter.addOne( {
            activity_id: Message.id,
            content: Message.message,
            activity_type: (Message.is_bot) ? 'message-bot' : 'message',
            activity_source: (Message.is_from_visitor) ? '' : Message.agent_id,
            visitor_id: Message.visitor_id,
            session_id: Message.session_id.toString(),
            is_read: Message.is_read,
            created_at: Message.created_at,
            attachment: Message.attachment,
            article_id: Message.article,
            is_private: Message.is_private
        } , state))
    ),
    on(
        ActivitiesActions.NewSession,
        (state, { id, created_at, visitor_id }) => (adapter.addOne( {
            activity_id: id.toString(),
            content: created_at,
            activity_type: 'session-start',
            activity_source: '',
            visitor_id: visitor_id,
            created_at: created_at
        } , state))

    ),
    on(
        ActivitiesActions.MessageReadByVisitorReceived,
        (state, { message_id }) => {

            const message_update: Update<Activity> = {
                id: message_id,
                changes: {
                    is_read: true
                }
            };

            return adapter.updateOne(message_update, state);
        } 
    ),
    on(
        ActivitiesActions.SendMessageFailed,
        (state, { error, message_id }) => {

            const message_update: Update<Activity> = {
                id: message_id,
                changes: {
                    is_failed: true
                }
            };

            return adapter.updateOne(message_update, state);
        } 
    )
);

export function activityReducer(state: ActivityState | undefined, action: Action) {
    return reducer(state, action);
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal  
} = adapter.getSelectors();
