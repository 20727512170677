import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Visitor } from '../../models';
import * as moment from 'moment';
import cloneDeep from 'lodash-es/cloneDeep';
import { createReducer, on, Action } from '@ngrx/store';
import * as VisitorsActions from './visitors.actions'
import { Paging } from '../../models/page.model';
import { environment } from 'src/environments/environment';

export interface VisitorState extends EntityState<Visitor> {
    isAllVisitorsLoaded: boolean,
    selectedVisitorId: string,
    selectedAgentId: string,
    paging: Paging,
    isVisitiorsLoading: boolean
}

export const adapter : EntityAdapter<Visitor> = createEntityAdapter<Visitor>({
    sortComparer: sortByLastMessage
});

function sortByLastMessage(e1: Visitor, e2: Visitor) {

    // handle cases where visitor does not have a last message
    let d1, d2;
    d1 = e1.last_message_created 
        ? moment( e1.last_message_created.replace('T', ' '), 'YYYY-MM-DD HH:mm:ss').toDate().getTime() 
        : moment( e1.temp_date_order.replace('T', ' '), 'YYYY-MM-DD HH:mm:ss').toDate().getTime() ;
    d2 = e2.last_message_created 
        ? moment( e2.last_message_created.replace('T', ' '), 'YYYY-MM-DD HH:mm:ss').toDate().getTime() 
        : moment( e2.temp_date_order.replace('T', ' '), 'YYYY-MM-DD HH:mm:ss').toDate().getTime() 

    if (d1 > d2) return -1;
    else if (d2 > d1) return 1;
    else return 0; 
}

const initialVisitorState: VisitorState = adapter.getInitialState({
    isAllVisitorsLoaded: false,
    selectedVisitorId: '',
    selectedAgentId: '',
    isVisitiorsLoading: false,
    paging: {
        limit: environment.recent_conversations_pagesize,
        page: 0,
        search: ""
    }
});

const reducer = createReducer(
    initialVisitorState,
    on(
        VisitorsActions.AllVisitorsRequested,
        (state, { paging }) => {
            return (paging.page == 0) ? (adapter.removeAll({...state, isVisitiorsLoading: true}))
                                        : ({...state, isVisitiorsLoading: true}) 
        }
    ),
    on(
        VisitorsActions.AllVisitorsRequestSuccess,
        (state, {visitors} ) => {
            return (visitors.pageNumber == 0) ?
                adapter.setAll(visitors.model, {
                    ...state, 
                    paging: {
                        total: visitors.itemsCount,
                        limit: environment.recent_conversations_pagesize,
                        page: visitors.pageNumber
                    },
                    isAllVisitorsLoaded: true,
                    isVisitiorsLoading: false
                })
                :
                adapter.addMany(visitors.model, {
                    ...state, 
                    paging: {
                        total: visitors.itemsCount,
                        limit: environment.recent_conversations_pagesize,
                        page: visitors.pageNumber
                    },
                    isAllVisitorsLoaded: true,
                    isVisitiorsLoading: false
                })
        }
    ),

    on(
        VisitorsActions.VisitorRequestSuccess,
        (state, { visitor }) => {
            let v = visitor;

            if ( visitor.last_message_created == null) {
                v = cloneDeep(visitor);
                v.temp_date_order =  moment.utc().format("YYYY-MM-DD HH:mm:ss");
            }
            return (adapter.upsertOne(v, state));
        }
    ),
    on(
        VisitorsActions.AllVisitorsReset,
        (state) => (adapter.removeAll({...state, 
            isVisitiorsLoading: false, 
            isAllVisitorsLoaded: false, 
            paging: {
                limit: environment.recent_conversations_pagesize,
                page: 0,
                search: ""
            }}))
    ),
    on(
        VisitorsActions.VisitorSelected,
        (state, {visitor_id}) => ({...state, selectedVisitorId: visitor_id}) 
    ),
    on(
        VisitorsActions.VisitorCurrentMessageSave,
        (state, { visitor }) => (adapter.updateOne(visitor, state))
    ),
    on(
        VisitorsActions.VisitorScrollPosition,
        (state, { visitor }) => (adapter.updateOne(visitor, state))
    ),
    on(
        VisitorsActions.VisitorCurrentMessageSave,
        (state, { visitor }) => (adapter.updateOne(visitor, state))
    ),
    on(
        VisitorsActions.VisitorInfoSaveSuccess,
        (state, { visitor }) => (adapter.updateOne(visitor, state))
    ),
    on(
        VisitorsActions.VisitorUnreadCount,
        (state, { visitor }) => (adapter.updateOne(visitor, state))
    ),
    on(
        VisitorsActions.VisitorUpdateSuccess,
        (state, { visitor }) => (adapter.updateOne(visitor, state))
    ),
    on(
        VisitorsActions.VisitorRemove,
        (state, { visitor_id }) => (adapter.removeOne(visitor_id, state))
    ),
    on(
        VisitorsActions.SetSelectedAgent,
        (state, {agent_id}) => ({...state, selectedAgentId: agent_id}) 
    ),
    on(
        VisitorsActions.SetPageNumber,
        (state, {page_number}) => ({...state, paging: {
            page: page_number,
            limit: state.paging.limit,
            search: state.paging.search
        } }) 
    ),
);


export function visitorsReducer(state: VisitorState | undefined, action: Action) {
    return reducer(state, action);
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
  
  } = adapter.getSelectors();

