import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Kb, KbResponse } from '../models/kb.model';
import { KbArticle, KbArticleResponse, KbArticleUploadImageResponse } from '../models/kbarticle.model';
import { KbCategory, KbCategoryResponse } from '../models/kbcategory.model';

const API_URL = environment.apiUrl;

@Injectable({
	providedIn: 'root'
})
export class KbService {

	constructor(private http: HttpClient) {
	}

    public getKb(org_id: number) : Observable<KbResponse>  {
        return this.http.get<KbResponse>(`${API_URL}/api/kb/${org_id}`);
    }

    public getKbArticle(article_id: number) : Observable<KbArticleResponse>  {
        return this.http.get<KbArticleResponse>(`${API_URL}/api/kb/articles/${article_id}`);
    }

    public saveKbArticle(article: KbArticle): Observable<KbArticleResponse> {
        return this.http.post<KbArticleResponse>(`${API_URL}/api/kb/article`, article);
    }

    public deleteArticle(article_id: number) : Observable<KbArticleResponse>  {
        return this.http.delete<KbArticleResponse>(`${API_URL}/api/kb/article/${article_id}`);
    }

    public uploadArticleImage(formData: FormData) : Observable<KbArticleUploadImageResponse>  {
        return this.http.post<KbArticleUploadImageResponse>(`${API_URL}/api/kb/articles/upload_image`, formData);
    }

    public saveKbCategory(category: KbCategory): Observable<KbCategoryResponse> {
        return this.http.post<KbArticleResponse>(`${API_URL}/api/kb/category`, category);
    }

    public deleteCategory(category_id: number) : Observable<KbCategoryResponse>  {
        return this.http.delete<KbCategoryResponse>(`${API_URL}/api/kb/category/${category_id}`);
    }

}
