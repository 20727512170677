import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

/**
 * @description Workaround Guard to prevent Angular from showing the error page
 * when browsing to /connect/authorize
 */

@Injectable({
  providedIn: 'root'
})
export class ConnectGuard implements CanActivate {
  constructor() {
  }

  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      
        if ( (_next.url.length > 0) && (_next.url[0].path == "connect")){
          console.debug("Ignoring /connect/authorize route");
          return false;
        }
        
        return true;

  }


}
