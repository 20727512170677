import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { identityConfig } from './identity-config';
import { IdentityGuardWithForcedLogin } from './identity-guard-with-forced-login.service';
import { IdentityGuard } from './identity.guard';
import { identityModuleConfig } from './identity-module-config';
import { IdentityService } from './identity.service';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [
    HttpClientModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    IdentityService,
    IdentityGuard,
    IdentityGuardWithForcedLogin,
  ],
})
export class IdentityModule {
  static forRoot(): ModuleWithProviders<IdentityModule> {
    return {
      ngModule: IdentityModule,
      providers: [
        { provide: AuthConfig, useValue: identityConfig },
        { provide: OAuthModuleConfig, useValue: identityModuleConfig },
        { provide: OAuthStorage, useFactory: storageFactory },
      ]
    };
  }

  constructor (@Optional() @SkipSelf() parentModule: IdentityModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}