// Anglar
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout Directives
// Services
import {
	ContentAnimateDirective,
	FirstLetterPipe,
	GetObjectPipe,
	HeaderDirective,
	JoinPipe,
	MenuDirective,
	OffcanvasDirective,
	SafePipe,
	StickyDirective,
	TabClickEventDirective,
	TimeElapsedPipe,
	ToggleDirective
} from './_base/layout';
import { CalendarDayPipe, CreatedDatePipe, SessionDayPipe, TransformSQLDatePipe } from './pipes/dates.pipe';
import { HasRoleDirective } from './hasrole.directive';
import { InlineSVGModule, InlineSVGDirective } from 'ng-inline-svg';
import { CharlaAvatarComponent } from './avatar/avatar.component';
import { ConnectedShopifyComponent } from './shopify/connected-shopify.component';
import { UniqueColorPipe } from './pipes/ucolor.pipe';
import { TimerComponent } from './timer/timer.component';
import { FloorPipe } from './pipes/numbers.pipe';
import { RocketEditModule } from './rocket-edit/rocket-edit.module';
import { MomentModule } from 'ngx-moment';
import { EscapeHtmlPipe, LinkifyPipe, ShortUrlPipe } from './pipes/url.pipe';
import { AttachmentComponent } from './attachment/attachment.component';
import { AttachmentImageComponent } from './attachment/attachmentimage.component';
import { TagEditorModule } from './tag-editor/tag-editor.module';
import { PlatformFormatPipe } from './pipes/platformFormat';
import { ReactiveFormsModule } from '@angular/forms';
import { KbSlugifyPipe, KbStatusPipe, KbVisibilityPipe } from './pipes/kb.pipes';
import { MessageArticleComponent } from './message-article/message-article.component';
import { CharlaPropertyLabelComponent } from './property-label/property-label.component';
import { PriceCurrencyPipe } from './pipes/currency.pipe';
import { WhatsnewComponent } from './whatsnew/whatsnew.component';

@NgModule({
	imports: [
		CommonModule, 
		InlineSVGModule,
		RocketEditModule,
		MomentModule,
		TagEditorModule,
		ReactiveFormsModule
	],
	declarations: [
		// directives
		HeaderDirective,
		OffcanvasDirective,
		ToggleDirective,
		MenuDirective,
		TabClickEventDirective,
			ContentAnimateDirective,
		StickyDirective,
		// pipes
		TimeElapsedPipe,
		JoinPipe,
		GetObjectPipe,
		SafePipe,
		FirstLetterPipe,
		CreatedDatePipe,
		PriceCurrencyPipe,
		HasRoleDirective,
		CharlaAvatarComponent,
		ConnectedShopifyComponent,
		UniqueColorPipe,
		TimerComponent,
		FloorPipe,
		SessionDayPipe,
		CalendarDayPipe,
		TransformSQLDatePipe,
		PlatformFormatPipe,
		ShortUrlPipe,
		LinkifyPipe,
		EscapeHtmlPipe,
		KbStatusPipe,
		KbVisibilityPipe,
		KbSlugifyPipe,
		AttachmentComponent,
		AttachmentImageComponent,
		MessageArticleComponent,
		CharlaPropertyLabelComponent,
		WhatsnewComponent
	],
	exports: [
		// directives
		HeaderDirective,
		OffcanvasDirective,
		ToggleDirective,
		MenuDirective,
		TabClickEventDirective,
		ContentAnimateDirective,
		StickyDirective,
		// pipes
		TimeElapsedPipe,
		JoinPipe,
		GetObjectPipe,
		SafePipe,
		FirstLetterPipe,
		CreatedDatePipe,
		PriceCurrencyPipe,
		HasRoleDirective,
		InlineSVGDirective,
		CharlaAvatarComponent,
		ConnectedShopifyComponent,
		UniqueColorPipe,
		TimerComponent,
		FloorPipe,
		SessionDayPipe,
		CalendarDayPipe,
		TransformSQLDatePipe,
		PlatformFormatPipe,
		KbStatusPipe,
		KbVisibilityPipe,
		KbSlugifyPipe,
		ShortUrlPipe,
		LinkifyPipe,
		EscapeHtmlPipe,
		AttachmentComponent,
		AttachmentImageComponent,
		MessageArticleComponent,
		CharlaPropertyLabelComponent
	],
	providers: [UniqueColorPipe]
})
export class CoreModule {
}
