import { createAction, props } from "@ngrx/store";
import { Paging } from "../../models/page.model";
import { Ticket, TicketsResponse } from "../models/ticket.model";
import { Update } from "@ngrx/entity";
import { TicketReply } from "../models/ticketreply.model";
import { Visitor } from "../../models";

export const TicketsRequested = createAction(
    '[Tickets] Tickets Requested',
    props<{paging: Paging}>(),
)

export const TicketsRequestSuccess = createAction(
    '[Tickets] Tickets Loaded',
    props<{tickets: TicketsResponse}>(),
)

export const SingleTicketsRequest = createAction(
    '[Tickets] Single Ticket Request',
    props<{ticket_id: number}>(),
)

export const NewTicketReceived = createAction(
    '[Tickets] New Ticket Received from SignalR',
    props<{ticket_id: number}>(),
)

export const NewReplyReceived = createAction(
    '[Tickets] New Reply Received from SignalR',
    props<{ticket_id: number}>(),
)

export const MarkTicketUnread = createAction(
    '[Tickets] Mark Ticket as Unread',
    props<{ticket_id: number}>(),
)

export const MarkTicketRead = createAction(
    '[Tickets] Mark Ticket as Read',
    props<{ticket_id: number}>(),
)

export const SingleTicketRequestSuccess = createAction(
    '[Tickets] Single Ticket Request Success',
    props<{ticket: Ticket}>(),
)

export const TicketSaveRequest = createAction(
    '[Tickets] Save Ticket Details Request',
    props<{ticket: Ticket}>(),
)

export const TicketSaveSuccess = createAction(
    '[Tickets] Save Ticket Details Success',
    props<{ticket: Ticket}>(),
)

export const TicketSaveFailed = createAction(
    '[Tickets] Save Ticket Failure',
    props<{message: string}>()
)

export const ReplySaveRequest = createAction(
    '[Tickets] Save Ticket Reply Request',
    props<{reply: TicketReply}>(),
)

export const ReplySaveSuccess = createAction(
    '[Tickets] Save Ticket Reply Success',
    props<{reply: TicketReply}>(),
)

export const ReplySaveFailed = createAction(
    '[Tickets] Save Ticket Reply Failure',
    props<{message: string}>()
)

export const AssigneeSaveRequest = createAction(
    '[Tickets] Save Assignees Request',
    props<{ticket: Update<Ticket>}>(),
)

export const AssigneeSaveSuccess = createAction(
    '[Tickets] Save Assignees Success',
    props<{ticket: Update<Ticket>}>(),
)

export const AssigneeSaveFailed = createAction(
    '[Tickets] Save Assignees Failure',
    props<{message: string}>()
)

export const StatusSaveRequest = createAction(
    '[Tickets] Save Status Request',
    props<{ticket: Update<Ticket>}>(),
)

export const StatusSaveSuccess = createAction(
    '[Tickets] Save Status Success',
    props<{ticket: Update<Ticket>}>(),
)

export const StatusSaveFailed = createAction(
    '[Tickets] Save Status Failure',
    props<{message: string}>()
)

export const PrioritySaveRequest = createAction(
    '[Tickets] Save Priority Request',
    props<{ticket: Update<Ticket>}>(),
)

export const PrioritySaveSuccess = createAction(
    '[Tickets] Save Priority Success',
    props<{ticket: Update<Ticket>}>(),
)

export const PrioritySaveFailed = createAction(
    '[Tickets] Save Priority Failure',
    props<{message: string}>()
)

export const TagsSaveRequest = createAction(
    '[Tickets] Save Tag Request',
    props<{ticket: Update<Ticket>}>(),
)

export const TagsSaveSuccess = createAction(
    '[Tickets] Save Tag Success',
    props<{ticket: Update<Ticket>}>(),
)

export const TagsSaveFailed = createAction(
    '[Tickets] Save Tag Failure',
    props<{message: string}>()
)


export const TicketDeleteRequest = createAction(
    '[Tickets] Delete Ticket Details Request',
    props<{ticket_id: number}>(),
)

export const TicketDeleteSuccess = createAction(
    '[Tickets] Delete Ticket Details Success',
    props<{ticket_id: number}>(),
)

export const TicketDeleteFailed = createAction(
    '[Tickets] Delete Ticket Details Failed',
    props<{message: string}>(),
)

export const TicketRemove = createAction(
    '[Tickets] Remove Ticket from View',
    props<{ticket_id: number}>(),
)

export const TicketsPropertySelected = createAction(
    '[Tickets] Property Selected',
    props<{property_id: number}>(),
)

export const SetSelectedTicket = createAction(
    '[Tickets] Set Selected Ticket',
    props<{id: number}>(),
)

export const SetView = createAction(
    '[Tickets] Change Ticket View Mode',
    props<{view: number}>(),
)

export const TicketContactSaveRequest = createAction(
    '[Tickets] Save Ticket Contact Request',
    props<{contact: Update<Visitor>, ticket_id: number }>(),
)

export const TicketContactSaveSuccess = createAction(
    '[Tickets] Save Ticket Contact Details Success',
    props<{contact: Visitor, ticket_id: number}>(),
)

export const TicketContactSaveFailed = createAction(
    '[Tickets] Save Ticket Contact Failure',
    props<{message: string}>()
)