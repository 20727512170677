import * as AuthActions from './auth.actions'
import { User, Organization } from '../../models';
import { Action, createReducer, on } from '@ngrx/store';

export interface AuthState {
    loggedIn: boolean;
    authToken: string;
    user: User;
    isUserLoaded: boolean;
    currentOrganization: Organization;
    saveLoading: boolean;
}

export const initialAuthState: AuthState = {
    loggedIn: false,
    authToken: undefined,
    user: undefined,
    isUserLoaded: false,
    currentOrganization: null,
    saveLoading: false
};

const reducer = createReducer(
    initialAuthState,
    on(
        AuthActions.Login,
        (state, {authToken}) => {
            const _token: string = authToken;
            return {
                loggedIn: true,
                authToken: _token,
                user: undefined,
                isUserLoaded: false,
                currentOrganization: null,
                saveLoading: false
            };
        }
    ),
    on(
        AuthActions.Register,
        (state, {authToken}) => {
            const _token: string = authToken;
            return {
                loggedIn: true,
                authToken: _token,
                user: undefined,
                isUserLoaded: false,
                currentOrganization: null,
                saveLoading: false
            };
        }
    ),
    on(
        AuthActions.Logout,
        (state) => (initialAuthState)
    ),
    on(
        AuthActions.UserLoaded,
        (state, {user}) => {
            const _user: User = user;
            return {
                ...state,
                user: _user,
                isUserLoaded: true,
                /* Just map it to the first organization of the user
                Later that would be choosed by the user, which organization he wants to be on */
                currentOrganization: _user.organizations[0]
            };
        }
    ),
    on(
        AuthActions.UserSaveRequest,
        (state, {user}) => ({...state, saveLoading: true})
    ),
    on(
        AuthActions.UserSaveSuccess,
        (state, {user}) => ({...state, user: user, saveLoading: false})
    ),
    on(
        AuthActions.UserSaveFailed,
        (state) => ({...state, saveLoading: false})
    ),
    on(
        AuthActions.UserSaveLoading,
        (state, {isLoading}) => ({...state, saveLoading: isLoading})
    ),
    on(
        AuthActions.UpdateAvailabilitySuccess,
        (state, {availability}) => ({
            ...state,
            user: {
                ...state.user,
                available: availability
            }
        })
    )
);

export function authReducer(state: AuthState | undefined, action: Action) {
    return reducer(state, action);
}