import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

export const identityConfig: AuthConfig = {
    issuer: environment.apiUrl,
    clientId: 'charla-spa', // The "Auth Code + PKCE" client
    responseType: 'code',
    scope: 'openid profile offline_access charla-api', // Ask offline_access to support refresh token refreshes
    
    redirectUri: environment.apiUrl + '/',
    silentRefreshRedirectUri: environment.apiUrl + '/silent-refresh.html',
      
    useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
    silentRefreshTimeout: 3 * 1000, // Ddefault value is 20 sec, we decreased because the electron app was not loading fast enough
    timeoutFactor: 0.75, // For faster testing
    sessionChecksEnabled: true,
    showDebugInformation: false, // Also requires enabling "Verbose" level in devtools
    clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
    nonceStateSeparator : 'semicolon' // Real semicolon gets mangled by IdentityServer's URI encoding
};