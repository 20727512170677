import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, from, combineLatest, forkJoin } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { isLoggedIn, currentUser } from '../auth.selectors';
import { AppState} from '../../core.state';
import { map, skipWhile } from 'rxjs/operators';

@Injectable()
export class RoleGuard implements CanActivateChild {

  constructor(
    public router: Router,
    private store: Store<AppState> ) {}

    canActivateChild(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {

      const expectedRoles = route.data.Roles;
      // If no roles are set on the route just allow it
      if (!expectedRoles) return true;

      return combineLatest([
          this.store.pipe(select(isLoggedIn)),
          this.store.pipe(select(currentUser)),
        ]).pipe(
          skipWhile( ([loggedIn, user]) => (user == null)),
          map( ([loggedIn, user]) => 
              {
                  if ( loggedIn && !(expectedRoles.some(r => user.role == r )) ) {
                      this.router.navigate(['/error/403'], {queryParams: {desc: 'You are not authorized to access this page.'}});
                      return false;
                  };
                  return true;
              }
          )
        );

  }

}